import React, { useEffect, useState } from 'react'
import styled, { css } from '@xstyled/styled-components'

import { TextBlock } from '../../components/ContentBlock/TextBlock'
import { ImageTextBlock } from '../../components/ContentBlock/ImageTextBlock'
import { Button } from '../../components/Button'
import { ContentBlock } from '../../components/ContentBlock'
import { Accordion } from './Accordion'
import { Image } from '../../components/Image'
import WeddingAppointmentIcon from '../../svg/WeddingAppointment.svg'

import { useUser } from '../../providers'
import { geolocation } from '@vercel/functions'

interface LocationsProps {
  locations: any
  isMedium: boolean
}

interface ItemProps {
  $isActive: boolean
}

interface GeoLocationData {
  city?: string
  country?: string
  flag?: string
  region?: string
  countryRegion?: string
  latitude?: string
  longitude?: string
}

const ContentWrapper = styled.div`
  ${({ theme }) => css`
    border-left: 1px solid black;
    padding: 0 2 0 8;
    position: relative;
    width: 100%;
    margin: 8 auto;
    display: flex;
    flex-direction: column;
    gap: 6;

    ${theme.mediaQueries.tablet} {
      border-left: none;
      margin: 0 auto;
      padding: 0;
    }
  `}
`

const CalendarWrapper = styled.div`
  ${({ theme }) => css`
    border-left: 1px solid black;
    padding-left: 10;
    position: relative;
    width: 100%;
    margin: 8 auto;
    display: flex;
    flex-direction: column;
    div:first-child > div {
      min-height: unset;
    }
    ${theme.mediaQueries.tablet} {
      border-left: none;
      margin: 0 auto;
      padding-left: 0;
    }
  `}
`

const ButtonsWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 6;
    width: 100%;
    z-index: 100;
    button {
      width: 100%;
      z-index: 100;
    }
    button:nth-child(2) {
      background-color: white;
      color: black;
      border: 1px solid black;
  `}
`

const Items = styled.ul`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 40px;
    cursor: pointer;
    margin: 10 0;
    padding: 0;
  `}
`

const Item = styled.li<ItemProps>`
  ${({ theme, $isActive }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6;

    @media (hover: hover) {
      &:hover {
        > span {
          outline: 1px solid ${theme.colors.grays[4]};
          border-radius: 10px;
        }
      }
    }

    ${$isActive &&
    `    >span {
      outline: 1px solid ${theme.colors.grays[6]}; 
      border-radius: 10px;
    }
    
    @media (hover: hover) {
      &:hover {
        > span {
          outline: 1px solid ${theme.colors.grays[6]};
          border-radius: 10px;
          }
        }
      }  
    `}
  `}
`

const TextWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
  `}
`
const IconWrapper = styled.span`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    width: 90;

    > div {
      aspect-ratio: 1;
      margin: 4px;
    }

    ${theme.mediaQueries.tablet} {
      width: 80;
    }
  `}
`

const CalendarText = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 6;
    align-items: center;

    > div > div {
      min-height: unset;
      height: unset;
      margin: 0;
    }
    svg {
      width: 80;
      height: 80;
    }
  `}
`

const Label = styled.span`
  ${({ theme }) => css`
    position: relative;
    font-size: 3;
    font-weight: 1;
    margin-bottom: 3;
    font-style: italic;
  `}
`

const Cta = styled.span`
  ${({ theme }) => css`
    position: relative;
    font-size: 5;
    font-weight: 2;
    text-transform: uppercase;
    text-decoration: underline;
  `}
`

export const Locations = ({ locations, isMedium }: LocationsProps) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [showCalendar, setShowCalendar] = useState(false)
  const [calendarIndex, setCalendarIndex] = useState(null)
  const [geolocationData, setGeolocationData] =
    useState<GeoLocationData | null>(null)

  const { logCustomEvent, getGeoLocation } = useUser()

  useEffect(() => {
    getGeoLocation().then((data) => {
      setGeolocationData(data)
    })
  }, [])

  const handleLocationClick = (index: number) => () => {
    setActiveIndex(index)
    setShowCalendar(false)
  }

  useEffect(() => {
    setActiveIndex(isMedium ? -1 : 0)
  }, [isMedium])

  return (
    <>
      <Items>
        {locations?.map((location, i) => {
          return isMedium ? (
            <Accordion
              index={i}
              isActive={activeIndex === i}
              setActiveIndex={setActiveIndex}
              key={i}
              renderLabel={() => (
                <Item
                  key={`${location?.label}-${i}`}
                  $isActive={activeIndex === i}
                >
                  <IconWrapper>
                    <Image image={location?.icon} objectFit="contain" />
                  </IconWrapper>
                  <TextWrapper>
                    <Label>{location?.label}</Label>
                    <Cta>{location?.ctaLabel}</Cta>
                  </TextWrapper>
                </Item>
              )}
            >
              {calendarIndex !== i ? (
                <ContentWrapper>
                  <ImageTextBlock content={location?.image} />
                  <ButtonsWrapper>
                    <Button
                      onClick={() => {
                        logCustomEvent('book_appointment', {
                          location: location?.label,
                          bookingStep: 'start',
                          country: geolocationData?.country,
                          city: geolocationData?.city,
                        })
                        setCalendarIndex(i)
                      }}
                    >
                      Book now
                    </Button>
                    {location?.phone && (
                      <Button>{`Call ${location?.phone}`}</Button>
                    )}
                  </ButtonsWrapper>
                </ContentWrapper>
              ) : (
                <CalendarWrapper>
                  <CalendarText>
                    <TextBlock content={location?.body} />
                  </CalendarText>
                  {location?.content?.map((content, i) => (
                    <ContentBlock key={i} content={content} />
                  ))}
                </CalendarWrapper>
              )}
            </Accordion>
          ) : (
            <Item
              $isActive={activeIndex === i}
              key={i}
              onClick={handleLocationClick(i)}
            >
              <IconWrapper>
                <Image image={location?.icon} objectFit="contain" />
              </IconWrapper>
              <TextWrapper>
                <Label>{location?.label}</Label>
                <Cta>{location?.ctaLabel}</Cta>
              </TextWrapper>
            </Item>
          )
        })}
      </Items>

      {!isMedium && locations && !showCalendar ? (
        <ContentWrapper>
          {activeIndex >= 0 && (
            <ImageTextBlock content={locations[activeIndex]?.image} />
          )}
          <ButtonsWrapper>
            <Button
              onClick={() => {
                setShowCalendar(true)
                logCustomEvent('book_appointment', {
                  location: locations[activeIndex]?.label,
                  bookingStep: 'start',
                  country: geolocationData?.country,
                  city: geolocationData?.city,
                })
              }}
            >
              Book now
            </Button>
            {locations[activeIndex]?.phone && (
              <Button>{`Call ${locations[activeIndex]?.phone}`}</Button>
            )}
          </ButtonsWrapper>
        </ContentWrapper>
      ) : (
        <CalendarWrapper>
          {!isMedium &&
            locations &&
            locations[activeIndex]?.content?.map((content, i) => (
              <>
                <CalendarText>
                  <WeddingAppointmentIcon />
                  <TextBlock content={locations[activeIndex]?.body} />
                </CalendarText>
                <ContentBlock key={i} content={content} />
              </>
            ))}
        </CalendarWrapper>
      )}
    </>
  )
}
